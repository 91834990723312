import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
      <header id="header">
        <a class="nav-brand" href="" target="_blank">
          <img id="header-img" src="logo2.png" alt="BRIGHTER TOMORROW PRIVATE LIMITED"/>
        </a>

        <input type="checkbox" id="toggle-menu" role="button"/>
        <label for="toggle-menu" class="toggle-menu">Menu</label>
  
      <nav id="nav-bar" class="navbar">
        <ul class="menu">
          <li><a class="nav-link" href="#featured">New in!</a></li>
          <li><a class="nav-link" href="#collections">Collections</a></li>
          <li><a class="nav-link" href="#about">About</a></li>
          <li><a class="nav-link" href="#contact">Contact</a></li>
        </ul>
        <ul class="social-menu">
          <li><a href="#"><i class="fa fa-pinterest" aria-hidden="true"></i></a></li>
          <li><a href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
          <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
          <li><a href="#"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
        </ul>
      </nav>
    </header>

    <div class="hero">
      <div class="hero-img">
        <img src={require("./pngegg.png")} style={{width: "80%"}} alt="BRIGHTER TOMORROW PRIVATE LIMITED"/>
      </div>
      <div class="hero-text">
        <h1>Your Skin Would Loved It!</h1>
        <h1>Vitamin C serems and lotions</h1>
        <a href="#featured" class="btn">Explore</a>
      </div>
    </div>


    <section id="featured">
      <div class="title title-left">
        <span class="line"></span><h3>New Friend!</h3>
      </div>
      <div class="wrapper">
        <div class="image">
          <img src={require("./100fsgghmkb3hbot4rd9lrm2p5.png")}  alt="Pixel Facial Cream"/>
        </div>
        <div class="text">
          <h2>Brighter tomorrow limited cosmetics</h2>
          <p>A lightweight formula that packs a punch with avocado protein extract to strengthen skin's own moisture barrier, sealing in hydration to protect, nourish and soften skin.</p>
          <a href="#" class="btn">Details</a>
        </div>
      </div>
    </section>



    <section id="collections">
      <div class="title title-right">
        <span class="line line-right"></span><h3>Vitamin Collections</h3>
      </div>
      <div class="wrapper">
        <a class="box box1" href="#">
          <h4>Moisturizers</h4>
          <div class="box-overlay"></div>
        </a>
        <a class="box box2" href="#">
          <h4>Lotions</h4>
          <div class="box-overlay"></div>
        </a>
        <a class="box box3" href="#">
          <h4>Cleansers & Toners</h4>
          <div class="box-overlay"></div>
        </a>
        <a class="box box3" href="#">
          <h4>All Body care and cosmetics many more</h4>
          <div class="box-overlay"></div>
        </a>
      </div>

      <div class="title title-right">
        <span class="line line-right"></span><h3>Product Collections</h3>
      </div>
      <div class="wrapper">
        <a class="box box1" href="#">
          <img class="box-image" src={require("./images/24K Gold Serum For All Skin Types .webp")}  alt="Pixel Facial Cream"/>
        </a>
        <a class="box box1" href="#">
          <img class="box-image" src={require("./images/24K Gold Serum For All Skin Types.webp")}  alt="Pixel Facial Cream"/>
        </a>
        <a class="box box1" href="#">
          <img class="box-image" src={require("./images/Black Mango (Breast Enlargement) Gel .webp")}  alt="Pixel Facial Cream"/>
        </a>
        <a class="box box1" href="#">
          <img class="box-image" src={require("./images/Black Plus (Penis Enlargement) Oil Spray.webp")}  alt="Pixel Facial Cream"/>
        </a>
        <a class="box box1" href="#">
          <img class="box-image" src={require("./images/Butter Boy Black Plus [Penis Enlargement] Oil.webp")}  alt="Pixel Facial Cream"/>
        </a>
        <a class="box box1" href="#">
          <img class="box-image" src={require("./images/Chocolate Scrub For All Skin Types.webp")}  alt="Pixel Facial Cream"/>
        </a>
        <a class="box box1" href="#">
          <img class="box-image" src={require("./images/Collagen Anti Aging Serum For All Skin Types.webp")}  alt="Pixel Facial Cream"/>
        </a>
        <a class="box box1" href="#">
          <img class="box-image" src={require("./images/Bump & Smooth CBD Body Serum.webp")}  alt="Pixel Facial Cream"/>
        </a>
       
      </div>
    </section>


  <section id="about">
    <div class="title title-left">
      <span class="line"></span><h3>BRIGHTER TOMORROW PRIVATE LIMITED</h3>
    </div>
    <div class="wrapper">
      <div class="text">
        <p>Since 2016, Pixel Skin Care has been at the forefront of the move towards organic and natural skincare.</p>
        <p>Specialising in emerging niche natural skin care brands, Pixel Skin Care is a safe zone for you and your skin where we have taken special care to hand pick and offer you some of the purest and safest brands from global organic specialists.</p>
        <p>From cutting edge science to luxurious natural indulgence, we have selected the best products and treatments for healing and improving your well-being.</p>
      </div>
      {/* <div class="video-wrapper">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/otej7WLdPh0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div> */}
      <div class="image">
          <img src={require("./pngwing.com.png")}  alt="Pixel Facial Cream"/>
      </div>
    </div>
  </section>


  <section id="contact">
    <div class="wrapper">
      <div class="form-wrapper">    
        <h4>Add More Beauty To Your Email</h4>
        <form id="form" action="">
          <input type="email" id="email" name="email" placeholder="Your email" required/>
          <input type="submit" id="submit" value="OK" class="submit"/>
        </form>
      </div>
      <div class="contact-wrapper">
        <h4>Stay In Touch With PIXEL</h4>
        <div class="wrapper">        
          <ul class="social-menu">
            <li><a href="#"><i class="fa fa-pinterest" aria-hidden="true"></i></a></li>
            <li><a href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
            <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
            <li><a href="#"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
          </ul>
          <div class="contactDetails">
            <div class="specific">
              <div></div>
              <div>
                <h4>Contact</h4>
                <p>+91 9540395547</p>
              </div>
            </div>
            <div class="specific">
              <div></div>
              <div><h4>Send us email at</h4><p>brightertomorrowprivatelimited@gmail.com</p></div>
            </div>
            <div class="specific">
              <div></div>
              <div><h4>Address</h4><p>901, AGGARWAL MILLENNIUM TOWER, NETAJI SUBHASH PLACE, PITAMPURA, DELHI, NORTH WEST DELHI, DELHI, 110034</p></div>
            </div>
          </div>
        </div>
      </div>
      <div class="copy-wrapper">
        <h6><i class="fa fa-copyright" aria-hidden="true"></i> Theme, Logo, Product Design by <a href="https://codepen.io/ann_">Ann</a></h6>
      </div>
    </div>
  </section>
  </>
);
}

export default App;
